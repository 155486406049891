import Layout from '../../components/Navigation/layout'
import * as React from "react";
import {useEffect, useState} from "react";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import {Client, handleApiError} from "../../services/ApiService";
import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './index.module.scss';
import FilterField from "../../components/FilterField/FilterField";
import {Link} from "gatsby";
import Loading from "../../components/Loading/Loading";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import {StaticImage} from "gatsby-plugin-image";

const VendorPage = () => {
    const [vendors, setVendors] = useState([]);
    const [applyImageLink, setApplyImageLink] = useState('')
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Client.Raw.get('Vendor/getActiveVendorsMinimalized').then((res) => {
            setVendors(res.data);
            setLoading(false)
        }).catch(handleApiError);

        Client.Raw.get('SiteConfig/1').then((res) => {
            setApplyImageLink(res.data.ApplyImageLink ?? '')
        }).catch(handleApiError);


    }, [])

    return (
        <Layout pageTitle="Händlerübersicht" menuID={4}>
            <Container className="py-5">
                {console.log(vendors)}
                {vendors.length > 0 ?
                    <Row>
                        {vendors.map((vendor, index) => {

                            return (
                                !vendor.IsPackageVendor ?
                                    <Col lg={3} xs={6} key={index}
                                         className={style.vendor + ' mb-3 mb-lg-5'}>
                                        <Link to={`/haendleruebersicht/${vendor.URLSegment}`}
                                              className={style.link}>
                                            {vendor.PreviewImage ?
                                                <img src={vendor.PreviewImage}
                                                     loading={"lazy"}
                                                     className={style.vendorImage}/> :
                                                <StaticImage src={'../../images/login-overlay-2x.png'}
                                                             alt={'background'}
                                                             placeholder="blurred"
                                                             className={style.vendorImage}/>
                                            }
                                            <div className={style.content}>
                                                {/*<h4> {vendor.Category}</h4>*/}
                                                <h2> {vendor.CompanyName} </h2>
                                            </div>
                                        </Link>
                                    </Col>
                                    : null
                            )
                        })}

                        {/*<Col lg={3} xs={6} key={999}
                             className={style.vendor + ' mb-3 mb-lg-5'}>
                            <Link to={`/register/vendor/`}
                                  className={style.link}>
                                    <img src={applyImageLink}
                                                 alt={'background'}
                                                 placeholder="blurred"
                                                 className={style.vendorImage}/>
                            </Link>
                        </Col>*/}

                    </Row>
                    : (loading ? <Loading type={'border'} message={'HändlerInnen werden geladen...'} /> : <Col lg={12} className={"d-flex justify-content-center"}>
                        <h2> Keine HändlerInnen gefunden </h2>
                    </Col>)
                }
            </Container>
        </Layout>
    )
}

export default VendorPage;
